:root {
    --primary-font: 'IBM Plex Sans Hebrew', sans-serif;
    --secondary-font: 'IBM Plex Sans Hebrew', sans-serif;
    --tertiary-font: 'IBM Plex Sans Hebrew', sans-serif;
  }
  
  body {
    font-family: var(--primary-font);
  }
  
  h1, h2, h3 {
    font-family: var(--secondary-font);
    font-weight: 700;
  }
  
  p, input, button {
    font-family: var(--primary-font);
    font-weight: 400;
  }
  
  .light-text {
    font-weight: 300;
  }
  
  .medium-text {
    font-weight: 500;
  }
  
  .accent-text {
    font-family: var(--tertiary-font);
  }