/* Container styles */
.calendar-container {
    direction: rtl;
    text-align: right;
    font-family: 'IBM Plex Sans Hebrew', sans-serif;
  }
  
  /* Main calendar styles */
  .rbc-calendar {
    direction: rtl;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Header styles */
  .rbc-header {
    padding: 8px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    white-space: pre-wrap;
    height: auto;
    min-height: 50px;
    text-align: center;
    line-height: 1.3;
    background-color: #f8f9fa;
  }
  
  .rbc-header span {
    display: block;
  }
  
  /* Toolbar styles */
  .rbc-toolbar {
    flex-direction: row-reverse;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px 8px 0 0;
  }
  
  .rbc-toolbar button {
    font-family: inherit;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    margin: 0 2px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .rbc-toolbar button:hover {
    background-color: #e9ecef;
  }
  
  .rbc-toolbar button.rbc-active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .rbc-btn-group {
    direction: ltr;
  }
  
  /* Date cell styles */
  .rbc-date-cell {
    padding: 4px;
    text-align: right;
    direction: rtl;
  }
  
  .day-cell {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2px;
    height: 100%;
    min-height: 24px;
  }
  
  .gregorian-date {
    font-size: 0.9em;
    color: #666;
    margin-right: 4px;
  }
  
  .hebrew-date {
    font-size: 0.9em;
    color: #333;
    font-weight: bold;
    margin-left: 4px;
  }
  
  /* Today's date highlight */
  .rbc-today {
    background-color: #f0f9ff;
  }
  
  /* Event styles */
  .rbc-event {
    background-color: #3174ad;
    color: #fff;
    border-radius: 4px;
    padding: 2px 5px;
    font-size: 0.85em;
    border: none;
    margin: 1px 0;
  }
  
  /* Holiday event styles */
  .calendar-event.holiday {
    background-color: #FFE4E1;
    color: #FF4500;
    border: 1px solid #FF4500;
    padding: 2px 4px;
    border-radius: 3px;
    margin: 1px 0;
    font-size: 0.85em;
  }
  
  .calendar-event.holiday.multi-day {
    background-color: #FFE4E1;
    opacity: 0.9;
  }
  
  .calendar-event .event-title {
    font-weight: bold;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .calendar-event .event-subtitle {
    font-size: 0.8em;
    opacity: 0.8;
  }
  
  /* Month view specific styles */
  .rbc-month-view {
    direction: rtl;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .rbc-month-row {
    direction: rtl;
  }
  
  .rbc-row-content {
    direction: rtl;
  }
  
  /* Week/Day view specific styles */
  .rbc-time-view {
    direction: rtl;
  }
  
  .rbc-time-header {
    direction: rtl;
  }
  
  /* Show more link styles */
  .rbc-show-more {
    background-color: transparent;
    color: #007bff;
    text-align: right;
    padding-right: 5px;
    font-size: 0.85em;
  }
  
  /* RTL specific adjustments */
  .rbc-rtl .rbc-date-cell {
    display: flex;
    justify-content: space-between;
    padding: 4px;
  }
  
  .rbc-rtl .rbc-date-cell > span {
    float: none;
  }
  
  /* Off-range date styling (dates from other months) */
  .rbc-off-range {
    color: #999;
  }
  
  .rbc-off-range-bg {
    background-color: #f8f9fa;
  }
  
  /* Current time indicator */
  .rbc-current-time-indicator {
    background-color: #f00;
    height: 2px;
  }
  
  /* Overlay popup styles */
  .rbc-overlay {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    padding: 10px;
  }
  
  .rbc-overlay-header {
    border-bottom: 1px solid #ddd;
    margin: -10px -10px 5px;
    padding: 10px;
    font-weight: bold;
    text-align: right;
  }
  
  /* Selection highlight */
  .rbc-selection-bg {
    background-color: rgba(0, 123, 255, 0.2);
  }
  
  /* Hover effect on events */
  .rbc-event:hover {
    background-color: #2861a7;
  }
  
  .calendar-event.holiday:hover {
    background-color: #FFD0CC;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .rbc-toolbar {
      flex-direction: column;
      gap: 10px;
    }
  
    .rbc-toolbar button {
      padding: 8px 12px;
    }
  
    .hebrew-date, .gregorian-date {
      font-size: 0.8em;
    }
  
    .rbc-header {
      font-size: 0.9em;
      min-height: 40px;
    }
  }
  
  /* Name prompt styles */
  .name-prompt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    direction: rtl;
  }
  
  .name-prompt-box {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .name-prompt-box h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .name-prompt-box p {
    margin-bottom: 1.5rem;
    color: #666;
  }
  
  .name-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    direction: rtl;
  }
  
  .name-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Loading and error states */
  .loading-container, .error-container {
    text-align: center;
    padding: 2rem;
    color: #666;
  }
  
  .error-container {
    color: #dc3545;
  }
  
  /* Custom scrollbar for better RTL support */
  .rbc-calendar ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .rbc-calendar ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .rbc-calendar ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .rbc-calendar ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .calendar-title {
    text-align: center;
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }

  .rbc-day-bg {
    position: relative;
    min-height: 120px;
  }
  
  .date-display {
    position: absolute;
    top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
    z-index: 1;
  }
  
  /* מסתיר את כל התאריכים המקוריים */
  .rbc-date-cell > span,
  .rbc-date-cell > div > span,
  .rbc-date-cell div span {
    display: none !important;
  }
  
  .rbc-button-link {
    display: none !important;
  }