/* Modal Container */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    direction: rtl;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Form Styles */
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input[type="text"],
  .form-group textarea,
  .form-group .date-picker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;
  }
  
  .form-group input[type="text"]:focus,
  .form-group textarea:focus,
  .form-group .date-picker:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  /* Images Section */
  .images-section {
    margin: 20px 0;
  }
  
  .images-input-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .text-area-container {
    flex: 1;
  }
  
  .image-notes {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .upload-button-container {
    display: flex;
    justify-content: flex-start;
  }
  
  .custom-file-upload {
    cursor: pointer;
  }
  
  .upload-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .upload-button:hover {
    background-color: #e9ecef;
    border-color: #ced4da;
  }
  
  /* Image Preview */
  .image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .image-preview-item {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .image-preview-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .remove-image {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.2s;
  }
  
  .remove-image:hover {
    background-color: #c82333;
  }
  
  /* Button Styles */
  .button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .submit-button,
  .cancel-button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #6c757d;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  
  /* Date Picker Customization */
  .date-picker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    direction: rtl;
  }
  
  /* Scrollbar Styling */
  .modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      padding: 15px;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .submit-button,
    .cancel-button {
      width: 100%;
    }
  
    .image-preview-item {
      width: 80px;
      height: 80px;
    }
  }